import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { debounce } from "lodash";
import moment from 'moment-timezone'
import store from '@/store'
import useFilterCounter from '@/components/Filters/useFiltersCount'

export default function useList($i18n) {
    const refListTable = ref(null)

    store.dispatch('tenant/getAll')

    const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
    const tenantFilter = ref(null)

    const toast = useToast()

    const tableColumns = [
        { label: 'actions.name', key: 'actions' },
        { label: 'layout_builder.preview', key: 'preview', sortable: true },
        { label: 'tenants.title', key: 'tenant.name', sortable: true },
        { label: 'users.title', key: 'users', sortable: false,
          formatter: (value, key, item) => {
            if(value) return value.map(item => { return item.email}).join(', ')
            return ""
          },
          tdClass: 'text-truncate unit-td'
        },
        { label: 'layout_builder.is_default', key: 'is_default', sortable: false },
    ]

    const perPage = ref(10)
    const totalList = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    watch([
      //tenantFilter,
      currentPage,
      perPage,
    ], () => {
        refetchData()
    })

    watch([ searchQuery ], debounce(() => { refetchData() }, 500));

    const dataMeta = computed(() => {

        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalList.value,
        }
    })

    const fetchList = debounce((ctx, callback) => {
        store.dispatch('layouts/getLayouts', {
            q: searchQuery.value,
            max: perPage.value,
            'page[n]': currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            id_tenant: tenantFilter.value
        }).then(response => {
            callback(response.data)
            totalList.value = response.meta.total;
        });
    }, 300);

    const formatDateAssigned = value => {
        const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
        return formattedDate
    }

    const refetchData = () => {
        refListTable.value.refresh()
    }

    // filters counter ============================
  const filters = ref({});
  const filtersCount = useFilterCounter(filters);
  const buildFilters = () => {
    filters.value = {
      tenant: tenantFilter.value,
    };
    refetchData();
  }
  const clearSearchFilters = () => {
    tenantFilter.value = null;
    filters.value = {};
    buildFilters();
  }
  // ============================================

    return {
        tableColumns,
        perPage,
        currentPage,
        totalList,
        fetchList,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        formatDateAssigned,
        refetchData,
        tenantOptions,
        tenantFilter,

        filtersCount,
        buildFilters,
        clearSearchFilters
    }
}
