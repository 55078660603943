<template>
  <div>
    <b-row>
      <b-col>
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
      </b-col>
      <b-col cols="6" class="pr-3 my-2 text-right">
        <button-filters
          @show-modal="$root.$emit('bv::show::modal', $event)"
          :filters-count="filtersCount"
          @clear-search-filters="clearSearchFilters"
        />
      </b-col>
    </b-row>
    <filter-modal
      @action="buildFilters()"
      @clear="clearSearchFilters()"
    >
      <template v-slot:main-filters>
        <filters
          :tenant-filter.sync="tenantFilter"
          :tenant-options="tenantOptions"
        />
      </template>
    </filter-modal>
    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card-group deck class="mx-2">
    <b-card class="m-0" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                variant="secondary"
                to="layouts/config-layout"
                v-if="$can('create', 'Layouts')"
              >
                <span class="text-nowrap text-capitalize">{{
                    $t("actions.add") + " layout"
                  }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell(preview)="data">
          <layout-preview :key="data.item.id" :layout="data.item" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="`layouts/config-layout/${data.item.id}`"
                             v-if="$can('edit', 'Layouts')">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.edit")
                }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)"
                             v-if="$can('delete', 'Layouts')">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50 text-capitalize">{{
                  $t("actions.delete")
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    </b-card-group>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BTable,
  BPagination,
} from "bootstrap-vue";
import {ref} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import vSelect from "vue-select";
import Filters from "./Filters.vue";
import useList from "./useList";
import Swal from "sweetalert2";
import Pagination from "@/components/Pagination.vue";
import LayoutPreview from "../components/GridView/widgets/LayoutPreview";
import Breadcrumb from "@/components/Breadcrumb.vue"
import FilterModal from "@/components/Filters/FilterModal.vue"
import ButtonFilters from "@/components/Filters/ButtonFilters.vue"

export default {
  components: {
    Breadcrumb,
    Filters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BTable,
    BPagination,
    Pagination,
    LayoutPreview,
    ButtonFilters,
    FilterModal,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      filtersCount,
      clearSearchFilters,
      buildFilters,
    } = useList();
    const toast = useToast();

    const confirmDelete = (id) => {
      Swal.fire({
        title: "¿Estás seguro de elimar el layout?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store.dispatch("layouts/delete", id).then(() => {
            refetchData();
            toast({
              component: ToastificationContent,
              props: {
                title: "Layout eliminado correctamente",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          });
        }
      });
    };

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.tenants.layouts");

    return {
      // BreadCrumbs
      nameNav,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      //
      searchQuery,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      confirmDelete,
      filtersCount,
      clearSearchFilters,
      buildFilters,
    };
  },
};
</script>



<style lang="scss">
.unit-td {
  max-width: 250px;
}
</style>
